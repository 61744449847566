'use client';

import React, { useEffect, useState } from 'react';

import { faLightbulb, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { NextUIProvider } from '@nextui-org/react';
import { setCookie } from 'cookies-next';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { useSession } from 'next-auth/react';
import { ThemeProvider as NextThemeProvider } from 'next-themes';
import { useDispatch, useSelector } from 'react-redux';

import AccountModal from '@/app/components/AccountModal';
import AuthGuard from '@/app/components/AuthGuard';
import { Loader } from '@/app/components/Loader';
import Nav from '@/app/components/Nav';
import { useAccountModal } from '@/app/contexts/AccountModalContext';
import { useFilterModal } from '@/app/contexts/FilterModalContext';
import { FilterModal } from '@/app/custom-search/FilterModal';
import { getUserPlan } from '@/services/user-plan';
import { AppDispatch } from '@/store';
import { selectLoading } from '@/store/loading/selectors';
import { updateUserPlan } from '@/store/user-plan';
import { selectUserPlan } from '@/store/user-plan/selectors';
import { Plan } from '@/types/subscription';

import { TABS } from '../constants';

import Brand from './Brand';
import Footer from './Footer';
import TabBtn from './Tab/TabBtn';

export default function MainLayout({
  component,
}: {
  component: React.ReactNode
}) {
  const dispatch = useDispatch<AppDispatch>();
  const [isClient, setIsClient] = useState(false);
  const [selectedTab, setSelectedTab] = useState('');
  const path  = usePathname();
  const { accountModal, setAccountModal } = useAccountModal();
  const { filterModal, setFilterModal } = useFilterModal();
  const userPlan = useSelector(selectUserPlan) as Plan;
  const { data: session, status } = useSession();
  const loadingVisible = useSelector(selectLoading);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (status === 'authenticated' && session?.user?.cqToken) {
      setCookie('cq_token', session.user.cqToken);
      getUserPlan()
        .then((res) => {
          dispatch(updateUserPlan(res.data.plan));
        })
        .catch((err) => console.log(err));
    }
  }, [status]);

  useEffect(()=> {
    if(TABS.includes(path) )
      setSelectedTab(path);
  },[path]);

  // remove warning
  if (!isClient) return <></>;

  return (
    <NextUIProvider>
      <NextThemeProvider attribute="class" defaultTheme="dark">
        {/* <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY || ''}> */}
        <AuthGuard>
          {loadingVisible && <Loader showLogo text='Loading...' />}
          <div className=" dark:bg-[url('/bg.jpg')] bg-[url('/bg_light.jpeg')] bg-cover min-h-screen dark:backdrop-blur flex justify-center">
            <div className="md:w-[70%] w-full" >
              <Nav></Nav>
              <main className="flex items-center flex-col min-h-[84vh]">
                <Brand />
                <div className="flex flex-col w-full">
                  <div className="justify-center flex flex-row dark:text-white text-black  p-3 lg:text-[1.3rem]">
                    <Link href={'/ai-analytics'} className="mx-5">
                      <TabBtn icon={faLightbulb} selected={selectedTab} target="/ai-analytics">AI Analytics</TabBtn>
                    </Link>
                    <div className="mx-5" onClick={() => setFilterModal(true)}>
                      <TabBtn icon={faMagnifyingGlass} selected={selectedTab} target="/custom-search">Custom Search</TabBtn>
                    </div>
                  </div>
                  <div className='dark:text-white text-black'>
                    {component}
                    <AccountModal
                      open={accountModal.open}
                      setOpen={(_open: boolean) => setAccountModal({ ...accountModal, open: _open })}
                      tab={accountModal.tab}
                      userPlan={userPlan}
                    />
                    <FilterModal
                      open={filterModal}
                      setOpen={setFilterModal}
                    />
                  </div>
                </div>
              </main>
              <Footer />
            </div>
          </div>
        </AuthGuard>
        {/* </GoogleReCaptchaProvider> */}
      </NextThemeProvider>

      {session && [Plan.PRO, Plan.BUSINESS].includes(userPlan) && (
        <Script src='/helpcrunch.js' />
      )}
    </NextUIProvider>
  );
}